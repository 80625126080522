import React from "react"
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-getcanonicallocales/polyfill'
import { injectIntl } from "gatsby-plugin-react-intl"
import Seo from "../../components/seo"
import ClientsTicker from "../../components/misc/clients-ticker";
import TickerData from "../../content/home/ticker.json"
import SliderData from "../../content/hybrid-app/slider-test.json"
import SimpleMailingList from "../../components/ctas/simple-mailing-list"
import Hybrid from "../../components/hybrid"
import HybridSlider from "../../components/misc/hybrid-slider"

const HybridAppTestPage = ({ intl }) => (
    <Hybrid>
        <Seo lang={intl.locale}
             title={intl.formatMessage({ id: "pages.home.title" })}
             description={intl.formatMessage({ id: "pages.home.description" })}
        />
        <HybridSlider content={SliderData[intl.locale]} />
        <ClientsTicker content={TickerData[intl.locale]} />
        <SimpleMailingList />
    </Hybrid>
)

export default injectIntl(HybridAppTestPage)
